<template>

   <div class="content">

         <div class="main">

            <div class="main-r">
                <h2 class="h2-1">
                    订单详情
                </h2>
                <div class="time1">
                    <div class="time1-l">
                        <span>下单时间</span>
                        <span>2019-2-2</span>
                    </div>
                    <div class="time1-c">
                        <span>订单号</span>
                        <span>3565357877876456</span>
                    </div>
                    <div class="time1-r">
                        <span>订单状态：</span>
                        <span class="span2">待付款</span>
                    </div>
                </div>

                <div class="wrap">
                    <div class="div1">
                    您的订单还未付款，为了避免订单失效，请及时付款哦~
                    </div>
                </div>

                <div class="div2">订单信息</div>
                <ul class="ul1">
                    <li class="first-li">
                        <span>订单金额：</span>
                        <span class="teshu">￥400</span>
                    </li>
                    <li>
                        <span>订单类型：</span>
                        <span>招标订单</span>
                    </li>
                    <li>
                        <span>收货信息：</span>
                        <span>张三,的点点滴滴防腐剂大道理人人</span>
                    </li>
                    <li>
                        <span>支付方式：</span>
                        <span>未选择</span>
                    </li>

                </ul>

                <div class="div3">南京华航商务</div>
                <div class="div4">
                   <div class="div4-1">
                        <div class="div4-1-left">需求名称</div>
                        <div class="div4-1-center">工期(天)</div>
                        <div class="div4-1-right">报价</div>
                    </div>

                     <ul class="ul2">
                        <li>
                            <div class="ul2-lil">
                                <div class="ul2-lil-p">此处为超过15个字</div>
                            </div>
                             <div class="ul2-lic">2</div>
                             <div class="ul2-lir">￥2000</div>
                        </li>
                    </ul>
                </div>

                <div class="div5">

                    <span>中标金额：￥1200</span>
                    <span>订单总金额(不含运费)</span>
                    <span class="price">
                        ￥3200
                    </span>
                    <button class="btn1">立即支付</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import Vue from 'vue';
    import Aside from "@/components/common/aside.vue";
    import Swiper from 'swiper';
    // import '@/swiper/css/swiper.min.css';

    Vue.filter("fil",function(info){//过滤
			return "共"+ info +"件产品"; //把item这个原本的info数据加上件产品返回出去
		})


    export default {
        name: "index",
        components: {Aside},//注册组件
        data() {
            return {

            }
        },
        methods:{

        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/common";
    h2,ul,li{
        margin:0;
        padding:0
    }
    a{
        text-decoration: none;
    }
    li{
        list-style: none;
    }
    *{
        margin:0;
        padding:0;
    }
    .content{
        height: 1192px;
        background:#f5f5f5;
        .main{
            height:1129px;
            margin:0 auto;
            .main-l{
                width: 181px;
                height: 1129px;
                background:#fff;
                float: left;
            }
            .main-r{
                width: 1000px;
                height: 1129px;
                background: #fff;
                float: right;
                .el-tabs__nav{
                    margin-left: 20px;

                }
                .el-tabs__item{
                    border:1px solid red !important;
                }
                .h2-1{
                    width:962px;
                    height: 55px;

                    border-bottom: 1px solid #e5e5e5;
                    margin:0 auto;
                    margin-top: 10px;

                    line-height: 55px;
                    font-size: 18px;
                }
                .time1{
                    width: 962px;
                    height: 55px;
                    line-height: 55px;
                    margin: 0 auto;
                    margin-top: 4px;
                    font-size: 14px;
                    color:#999999;
                    >div{
                        float:left;
                        margin-left: 30px;
                    }
                    .time1-l{
                         margin: 0;
                    }
                    .time1-r{
                         .span2{
                             color:#333333;
                         }
                    }
                }
                .wrap{
                     width: 961px;
                    height: 50px;
                    background: #e6f7f0;
                    margin:0 auto;
                    z-index: 3;
                }
                .div1{
                    padding-left: 37px;
                       margin-left: 18px;
                    width: 927px;
                    height: 50px;
                    background:url("../../../assets/image/jinggao.png") no-repeat 10px 18px;
                    line-height: 50px;
                    color: #0db168;
                    font-size: 15px;
                    padding-left: 37px;
                }
                .div2,.div3{
                    width: 962px;
                    height: 50px;
                    font-size: 16px;
                    line-height: 16px;
                    margin:0 auto;
                    margin-top: 23px;
                    height: 43px;
                }
                .div3{
                    padding-left: 51px;
                    background: url("../../../assets/image/fangwu.png") no-repeat 20px 1px;
                }
                .div4{
                    width: 960px;
                    border: 1px solid #e5e5e5;
                     margin:0 auto;
                    .div4-1{
                        width: 960px;
                        height:44px;
                        background: #f5f5f5;
                        margin:0 auto;
                        font-size: 15px;
                        line-height: 44px;
                        color: #656565;
                        .div4-1-left{
                            text-align: center;
                            width:312px;
                            float:left;
                        }
                        .div4-1-center{

                            width:370px;
                            text-align: center;
                            float:left;
                        }
                         .div4-1-right{
                            width:202px;
                            text-align: center;
                            float:left;
                        }

                    }
                    .ul2{
                        margin:0 auto;
                        width: 960px;
                            height:120px;

                        li{
                            width: 960px;
                            height:120px;
                            border-bottom: 1px solid #e5e5e5;
                            .ul2-lil{
                                width: 312px;
                                height: 100%;
                                float: left;
                                .ul2-lil-img{
                                    margin-left: 17px;
                                    margin-top: 20px;
                                    width: 80px;
                                    height: 100px;
                                img{
                                    display: block;
                                    width: 80px;
                                    height: 80px;
                                }
                             }
                             .ul2-lil-p{
                                 text-align: center;
                                font-size: 15px;
                                width: 223px;
                                overflow:hidden;
                                text-overflow:ellipsis;
                                white-space:nowrap;
                                color:#333;
                                margin:17px 0 0 43px;

                            }

                            >div{
                                float:left;
                                 }
                            }
                            .ul2-lic{
                                width: 370px;

                                float:left;
                                text-align: center;
                                margin-top: 20px;
                            }
                            .ul2-lir{
                                width: 202px;
                               color:#ff0036;
                                float:left;
                                text-align: center;
                                margin-top: 20px;
                            }
                        }
                    }

                }
                .div5{
                    width: 960px;
                    margin: 0 auto;
                    height:94px;
                    line-height: 94px;
                    text-align: right;
                    color: #333;
                        span{
                            margin-right: 30px;
                        }

                    .btn1{
                         width: 100px;
                        height:36px;
                        background:#0db168;
                        border-radius: 3px;
                        border: none;
                        font-size: 15px;
                        color:#fff;
                    }
                    .price{
                        color: #ff0036;
                        font-size: 22px;
                    }

                }
            }
            .ul1{
                    width: 960px;
                    height: 130px;
                    border:1px solid #eaeaea;
                    margin:0 auto;
                    li{
                        height: 27px;
                        line-height:27px;
                        font-size: 15px;
                        color:#666666;
                        padding-left: 16px;
                        .teshu{
                            color:#ff264c;
                        }

                    }
                    .first-li{
                            margin-top: 10px;
                        }
                }

        }
    }

</style>
