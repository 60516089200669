<template>
  <div id="aside">
    <div class="content flex-y flex-center">
<!--      <div class="wxWrap" @mouseenter="enter()" @mouseleave="leave()">-->
<!--        <img src="../../assets/icon/wxicon.png" alt="" >-->
<!--        <span>客服微信</span>-->
<!--         <img src="../../assets/icon/wxServer.png" alt="" class="wxma" v-show="iswxma">-->
<!--      </div>-->
      <div>
        <img src="../../assets/icon/kefu.png" alt="">
        <a class="server" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=1760077306&site=qq&menu=yes">
        <span>客服Q Q</span>
        </a>
      </div>
      <div @click="$router.push('/personOrder')">
        <img src="../../assets/icon/gerenzhongxin.png" alt="">
        <span>个人中心</span>
      </div>
      <div @click="$router.push('/join')">
        <img src="../../assets/icon/ruzhu.png" alt="">
        <span>商家入驻</span>
      </div>
      <div class="go_up" @click="upScroll">
        <img src="../../assets/icon/fanhuidingbu.png" alt="">
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
let screen ="";
screen +=  window.screen.width;
export default {
  data() {
    return {
      iswxma:false,
      time: null,
      speed: 50, //speed变量, 滑动速度
      currentPosition: 0
    }
  },
  mounted() {
    this.screen();
  },
  methods: {
     screen(){
      console.log(screen)
      if (screen<=1440){
        document.getElementById("aside").style.right="0px";
      }
    },
    enter(){
       this.iswxma=true;
      },
      leave(){
       this.iswxma=false;
      },
    ...mapMutations({ saveUser: 'USER' }),
    upScroll() {
      this.currentPosition = document.documentElement.scrollTop || document.body.scrollTop
      this.timer = setInterval(() => {
        this.runToTop()
      }, 1)
    },
    runToTop() {
      this.currentPosition -= this.speed
      if (this.currentPosition > 0) {
        window.scrollTo(0, this.currentPosition)
      } else {
        window.scrollTo(0, 0)
        clearInterval(this.timer)
      }
    },
    logout() {
      this.$confirm('确认退出当前用户登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(key => {
          localStorage.removeItem ('TOKEN')
          localStorage.removeItem ('lat')
          localStorage.removeItem ('lng')
          this.saveUser({})
        })
        .catch(() => {})
    }
  },
  computed: {
    ...mapGetters(['user', 'isLogin', 'cartItems'])
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/common.scss';
a{
  text-decoration: none;
}
*{
  margin:0;
  padding:0;
}
#aside {
  width: 60px;
  height: 60%;
  position: fixed;
  top: 20%;
  right: 100px;
  z-index: 100;
  .content {
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
    border-radius: 4px;
    >div{
      height: 100px;
      background: #333;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0;
      align-items: center;
      border-bottom: 1px solid #999;
      cursor: pointer;
      span{
        width:37px;
        display: block;
        padding-left: 2px;
        color: #fff;
      }
    }
    >:nth-child(2){
      padding: 13px 0;
    }
    >:nth-child(4){
      border: none;
      padding: 15px 0;
    }
    .wxWrap{
      .wxma{ //微信二维码
        top: 20%;
        right: 170px;
        position: fixed;
        display: block;
      }
    }
    .go_up {
      height: 60px;
    background: #d82229;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    img{
      display: block;
    }
  }
  }


}
</style>
